import "./Tools.css";

import CENGHeader from "../../components/ctemplatecomponents/CENGHeader";
import ENGHeader from "../../components/engcomponents/ENGHeader";
import FundTools from "../../components/engcomponents/Fund";
import React from "react";
import SitTools from "../../components/engcomponents/Sit";
import fire from "../../config/shit";

class Tools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
    this.authListener = this.authListener.bind(this);
  }

  componentDidMount() {
    this.authListener();
  }

  authListener() {
    fire.auth().onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        this.setState({ user });
        localStorage.setItem("user", user.uid);
      } else {
        this.setState({ user: null });
        localStorage.removeItem("user");
      }
    });
  }

  render() {
    if (this.state.user != null) {
      return (
        <div>
          <CENGHeader auth="true" />
          <div className="Hero">
            <div className="HeroGroup">
              <div>
                <h1 id="ToolTitles1">Power Tools</h1>
                <FundTools />

                <h1 id="ToolTitles2">Situational Tools</h1>
                <SitTools />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.user == null) {
      return (
        <div>
          <CENGHeader auth="true" />
          <div className="Hero">
            <div className="HeroGroup">
              <div>
                <h1>You Need To Log In to Access This Page</h1>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Tools;
