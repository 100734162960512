import React from 'react'
import Link, { replace } from 'gatsby-link'

import logo from '../../Images/logo.png';
import fire from '../../config/shit';

import '../engcomponents/ENGHeader.css';


class CENGHeader extends React.Component {
    constructor(props) {
        super(props)

        this.handleLogOut = this.handleLogOut.bind(this);
        this.state = {
            hasScrolled: false
        }
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll)
    }

    handleScroll = (event) => {

        const scrollTop = window.pageYOffset

        if (scrollTop > 50) {
            this.setState({ hasScrolled: true})

        } else {
            this.setState({ hasScrolled: false})
        }

    }

    handleLogOut() {
        fire.auth().signOut().then(u => {
            replace('/ctemplate')
        });
        
    }

  

    render () {

       

            return (

               <div className={this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
                    <div className="HeaderGroup">
                        <Link to="/ctemplate"><img className="HeaderLogo" src={logo} width="30"></img></Link>
                        <Link to="/ctemplate">Welcome</Link>
                        <Link to="/ctemplate/Tools">Tools</Link>
                        <Link to="/ctemplate/Account">Account</Link>
                        <Link to="/"><button className="HeaderButton" onClick={this.handleLogOut}>Log Out</button></Link>
                    </div>
                </div>
            );

       

        
    }
}

export default CENGHeader;